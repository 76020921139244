import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import OrgChartLURIN from "./orgChart";
import axios from "axios";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import avatarPersonnel from "./assets/avatar-personnel.svg";
import LurinLoader from "./loader";
import {
  AuthenticatedTemplate,
  useMsal,
  MsalAuthenticationTemplate,
  MsalProvider,
} from "@azure/msal-react";

const msalConfig = {
  auth: {
    clientId: "c187019f-e545-4b25-8131-8f0139ec7041",
    authority:
      "https://login.microsoftonline.com/3b849bac-1356-49f1-825e-8c69d15eb213",
    // redirectUri: "http://localhost:3000/",
    redirectUri: "https://orgchart.lurin.com",
    // redirectUri: "https://nice-mud-0a3ffe610.3.azurestaticapps.net/",
  },
};

const scopes = ["User.Read"];

const msalInstance = new PublicClientApplication(msalConfig);

// set the active account
// const accounts = msalInstance.getAllAccounts();
// if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
// }

const fetchImage = async (url, accessToken) => {
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "image/jpeg",
      },
      // responseType: "arraybuffer",
    });
    // const base64String = new Buffer.from(response.data, "binary").toString(
    //   "base64"
    // );
    // return `data:image/jpeg;base64,${base64String}`;
    if (response.status !== 200) {
      return undefined;
    }
    const blob = await response.blob();
    return await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result);
      };
      reader.readAsDataURL(blob);
    });
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   const base64String = reader.result;
    //   return base64String;
    // setImageData(base64String);
    // };
    // return response;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const App = () => {
  const [accessToken, setAccessToken] = useState("");

  const [tree, setTree] = useState();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imgBase64, setImgBase64] = useState("");
  // let { id } = useParams();

  const { instance, inProgress, accounts } = useMsal();
  useEffect(() => {
    const login = async () => {
      try {
        const response = await instance.loginRedirect({ scopes });
        setAccessToken(response.accessToken);
      } catch (error) {
        console.error(error);
      }
    };
  }, []);

  useEffect(() => {
    const login = async () => {
      try {
        const response = await instance.loginRedirect({ scopes });
        setAccessToken(response.accessToken);
      } catch (error) {
        console.error(error);
      }
    };

    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          setAccessToken(accessToken);
          // callApi(accessToken).then((response) => {
          //   setApiData(response);
          // });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    } else {
      login();
    }
  }, [instance, accounts, inProgress, apiData]);

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken) {
        setIsLoading(true);

        const url =
          "https://graph.microsoft.com/v1.0/users?$expand=directReports($levels=max;$select=id,displayName,jobTitle)";
        let nextLink = url;
        const dataArray = [];

        while (nextLink) {
          // const response = await fetch(nextLink, { headers });
          const response = await axios.get(nextLink, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          dataArray.push(...response.data.value);
          // const responseData = await response.json();
          // const newData = responseData.value;
          nextLink = response.data["@odata.nextLink"];
          // fetchImage(
          //   `https://graph.microsoft.com/v1.0/users/1a9fdafb-609d-49ff-9ac3-a50d6ecbf0f2/photo/$value`,
          //   accessToken
          // );
        }
        setApiData([...dataArray]);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [accessToken]);

  const formatOrgChartData = async (
    nodeId = "1a9fdafb-609d-49ff-9ac3-a50d6ecbf0f2",
    childNode = false
  ) => {
    const createChildren = async (dataArg, childNodeArg) => {
      if (childNodeArg) {
        return undefined;
      }
      // const createChildrenData = dataArg.find((e) => e.id === childNodeId);

      if (dataArg.directReports?.length) {
        const childs = dataArg.directReports.map((reportee) =>
          formatOrgChartData(reportee.id, true)
        );
        return Promise.all(childs);
      }
      return [];
    };

    // const createChildren = (dataArg, childNodeArg) => {
    //   if (childNodeArg) {
    //     return undefined;
    //   }

    //   if (dataArg.reportees?.length) {
    //     return dataArg.reportees.map((reportee) =>
    //       formatOrgChartData(reportee.id, true)
    //     );
    //   }

    //   return [];
    // };

    const data = apiData.find((e) => e.id === nodeId);
    if (!data) {
      console.log("Not found", nodeId);
    }
    const bas64 = await fetchImage(
      `https://graph.microsoft.com/v1.0/users/${data?.id}/photo/$value`,
      accessToken
    );
    const children = await createChildren(data, !!childNode);
    const response = {
      id: data.id,
      person: {
        id: data.id,
        // avatar: `https://graph.microsoft.com/v1.0/users/${data?.id}/photo/$value`,
        avatar: bas64 ?? avatarPersonnel,
        // avatar:
        //   "https://home.relayhumancloud.com/Services/userphotos/DownloadPhoto.aspx?username=kishan.gohil@relayhumancloud.com",
        department: "",
        name: data.displayName,
        title: data.jobTitle ?? "",
        totalReports: data.directReports?.length ?? 0,
        link: data.directReports?.length
          ? `${window.location.origin}/org-chart/${data.id}`
          : undefined,
      },
      hasChild: data.directReports?.length
        ? data.directReports?.length > 0
        : false,
      hasParent: false,
      children,
      // children: createChildren(dataArray, !!childNode, data.id),
    };
    return response;
  };

  // Find a node by id
  // Get direct reportees of employeee
  // Map direct reportees and call formatOrgChartData
  // return array response

  const getChilds = async (id) => {
    const data = apiData.find((e) => e.id === id);
    if (!data) {
      console.log("Not found in get child", id);
    }
    const response = data.directReports.map((reportees) =>
      formatOrgChartData(reportees.id)
    );
    return Promise.all(response);
  };
  if (isLoading) {
    return <LurinLoader />;
  }

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <BrowserRouter>
        <Routes>
          <Route
            index
            exact
            element={
              <OrgChartWrapper
                setTree={setTree}
                apiData={apiData}
                tree={tree}
                formatOrgChartData={formatOrgChartData}
                getChilds={getChilds}
              />
            }
          />
          <Route
            path="/org-chart/:id"
            element={
              <OrgChartWrapper
                tree={tree}
                formatOrgChartData={formatOrgChartData}
                getChilds={getChilds}
                apiData={apiData}
                setTree={setTree}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </MsalAuthenticationTemplate>
  );
};

export default App;

const OrgChartWrapper = ({
  tree,
  formatOrgChartData,
  getChilds,
  apiData,
  setTree,
}) => {
  let { id } = useParams();
  useEffect(() => {
    const fetchandFormat = async () => {
      if (apiData.length > 0) {
        setTree();
        const formated = await formatOrgChartData(id ?? undefined);
        setTree(formated);
      }
    };
    fetchandFormat();
  }, [apiData, id]);
  return <OrgChartLURIN tree={tree} getChilds={getChilds} />;
};
