import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "c187019f-e545-4b25-8131-8f0139ec7041",
    authority:
      "https://login.microsoftonline.com/3b849bac-1356-49f1-825e-8c69d15eb213",
    // redirectUri: "http://localhost:3000/",
    redirectUri: "https://orgchart.lurin.com",
    // redirectUri: "https://nice-mud-0a3ffe610.3.azurestaticapps.net/",
  },
};

const scopes = ["User.Read"];

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
