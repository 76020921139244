import React from "react";

const LurinLoader = () => {
  return (
    <div className="loader">
      <div class="holder">
        <div class="preloader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {/* <span>Loading...</span> */}
    </div>
  );
};

export default LurinLoader;
